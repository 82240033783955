html, body, #root, .route-wrapper{
  height: 100%;
}

body {
  font-size: 15px;
  background-color: #282c34;
  color: white;
}


.App, .home-page, .list {
  height: 100%;
}

h4 {
  margin: .5rem
}

input {
  display: block;
}

.sticky {
  position: fixed;
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
  list-style: none;
  background-color: orange;
  color: black;
  border-bottom: dotted 1px white;
  z-index: 2;
  cursor: pointer;
}

.footer {
  bottom: 0px;
  max-width: 25%;
}

.link-home {
  top: 0px;
  right: 0px;
  padding: 20px;
}

nav:hover {
  background-color:darkorange;
}


.x {
  padding: 5px;
}

nav a {
  color: inherit
}

nav li {
  list-style: none;
}

main {
  margin: 0 auto;
  width: 600px;
  border: dotted 1px white;
  border-bottom: none;
  border-top: none;
  position: relative;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: scroll;
}

hr {
  width: 90%
}

.show-more-tweets {
  padding: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.show-more-tweets:hover {
  color: orange;
}

.avatar {
  width: 54px;
  height: 54px;
  border-radius: 50%;
}

.tweet .left {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
}

.tweet {
  font-size: .9rem;
  width: 100%;
  display: grid;
  grid-template-columns: 65px auto;
  border-bottom: dotted 1px white;
  padding: 15px;
}

.tweet .right {
  padding-left: 10px;
}

.tweet .author .name {
  font-weight: 800;
}

.tweet .author .handle {
  padding-left: 5px;
  font-weight: 200;
}

.tweet .right p {
  margin: 5px;
}

a {
  text-decoration: none;
}

.list {
  margin: 0 auto;
  width: 600px;
  color: white;
}

.list-title {
  color: white;
  border: dotted 1px white;
  padding: 50px;
}

.list-title:hover {
  background-color: orange;
  color: black;
}

.description {
  text-align: right;
}

.description a {
  color: white;
  text-decoration: underline;
}

input {
  height: 20px;
  width: 33%;
}

.home-spinner-container {
  display: flex;
  justify-content: center;
}

@media(max-width: 600px) {
  main {
    width: auto;
    margin: 5px;
  }

  .list { 
    width: auto;
    margin: 5px;
  }

  .footer {
    display: none;
  }

  .home-page {
    text-align:  center;
  }

  .link-home {
    padding: 8px;
  }

  input {
    margin: 0 auto;
  }

  .description {
    padding: 10px;
  }

}